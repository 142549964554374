import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Select, FormControl, MenuItem, InputLabel, TextField, IconButton, InputAdornment, Input } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import MultipleValueTextInput from 'react-multivalue-text-input';

import './style.scss';
import { countries } from '../../common/countries';

/**
 * Attivazione modale per aggiungere una vpn machine
 * 
 * titleModal: titolo della modale
 * click: funzione da attivare al click di OK
 * stateModal: stato della modale (booleano)
 * successNotify
 * errorNotify
 * warningNotify
 * toggle: per chiudere la modale
 * key: non viene passato per le operazioni di modale ma solo per permetter di resettare il costruttore ogni volta che apro la modale
 */
export default class modalVpnMachine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: this.props.vpnTempEdit.key || '',
            host: this.props.vpnTempEdit.credentials ? this.props.vpnTempEdit.credentials.host || '' : '',
            rootUser: this.props.vpnTempEdit.credentials ? this.props.vpnTempEdit.credentials.userId || '' : '',
            rootPassword: this.props.vpnTempEdit.credentials ? this.props.vpnTempEdit.credentials.password || '' : '',
            subnet: this.props.vpnTempEdit.subnet || '',
            mantainer: this.props.vpnTempEdit.mantainer || '',
            country: this.props.vpnTempEdit.country || '',
            publicIps: this.props.vpnTempEdit.publicIp || [],
            publicHost: this.props.vpnTempEdit.publicHost || '',
            city: this.props.vpnTempEdit.city || '',
            model: this.props.vpnTempEdit.model || '',
            os: this.props.vpnTempEdit.os || '',
            cpuDescription: this.props.vpnTempEdit.cpuDescription || '',
            cpuCores: this.props.vpnTempEdit.cpuCores || '',
            networkInterfaceName: this.props.vpnTempEdit.networkInterfaceName || '',
            maxBandwidth: this.props.vpnTempEdit.maxBandwidth || '',

            valuePublicIps: '',
            arrayTempIp: [],
            formIsValid: false,
            showPassword: false
        }
        this.setChange = this.setChange.bind(this);
        this.changeShowPassword = this.changeShowPassword.bind(this);
    }

    setChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    changeShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    }

    /**
     * Se i dati sono corretti, prepara l'oggetto da mandare al BE
     * per aggiungere o modificare una VPN
     */
    sendVpn = () => {
        if (this.formIsValid()) {
            let data = {};
            if (this.state.key === '') {
                data = {
                    host: this.state.host,
                    rootUser: this.state.rootUser,
                    rootPass: this.state.rootPassword,
                    subnet: this.state.subnet,
                    mantainer: this.state.mantainer,
                    country: this.state.country,
                    publicIps: this.state.publicIps,
                    publicHost: this.state.publicHost,
                    city: this.state.city,
                    model: this.state.model,
                    os: this.state.os,
                    cpuDescription: this.state.cpuDescription,
                    cpuCores: this.state.cpuCores,
                    networkInterfaceName: this.state.networkInterfaceName,
                    maxBandwidth: this.state.maxBandwidth
                }
            } else {
                data = {
                    key: this.state.key,
                    host: this.state.host,
                    rootUser: this.state.rootUser,
                    rootPass: this.state.rootPassword,
                    subnet: this.state.subnet,
                    mantainer: this.state.mantainer,
                    country: this.state.country,
                    publicIps: this.state.publicIps,
                    publicHost: this.state.publicHost,
                    city: this.state.city,
                    model: this.state.model,
                    os: this.state.os,
                    cpuDescription: this.state.cpuDescription,
                    cpuCores: this.state.cpuCores,
                    networkInterfaceName: this.state.networkInterfaceName,
                    maxBandwidth: this.state.maxBandwidth
                }
            }
            this.props.click(data);
        } else {
            this.props.errorNotify('Compila tutti i campi!');
        }

    }

    /**
     * Controlla se i campi sono stati inseriti tutti
     */
    formIsValid = () => {
        if (this.state.host === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.rootUser === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.rootPassword === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.subnet === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.mantainer === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.country === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.city === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.model === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.os === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.cpuDescription === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.cpuCores === 0 || this.state.cpuCores === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.networkInterfaceName === '') {
            this.setState({ formIsValid: true });
            return false;
        } else if (this.state.maxBandwidth === 0 || this.state.maxBandwidth === '') {
            this.setState({ formIsValid: true });
            return false;
        } else {
            this.setState({ formIsValid: false });
            return true;
        }
    }

    setPublicIps = (item, allItems) => {
        this.setState({
            publicIps: allItems,
            valuePublicIps: ''
        });
    }

    render() {
        const { stateModal, toggle, titleModal } = this.props;

        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalVpn">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>
                    <ModalBody>
                        <FormControl className="choose-form">
                            <div className="row">
                                <div className="col-6">
                                    <TextField
                                        name="host"
                                        label="Host"
                                        value={this.state.host}
                                        onChange={this.setChange}
                                        error={this.state.host === '' && this.state.formIsValid}
                                    />
                                </div>
                                <div className="col-6">
                                    <TextField
                                        name="subnet"
                                        label="Subnet"
                                        value={this.state.subnet}
                                        onChange={this.setChange}
                                        error={this.state.subnet === '' && this.state.formIsValid}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <TextField
                                        name="rootUser"
                                        label="Root User"
                                        value={this.state.rootUser}
                                        onChange={this.setChange}
                                        error={this.state.rootUser === '' && this.state.formIsValid}
                                    />
                                </div>
                                <div className="col-6">
                                    <FormControl>
                                        <InputLabel htmlFor="standard-adornment-password">Root Password</InputLabel>
                                        <Input
                                            name="rootPassword"
                                            label="rootPassword"
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            value={this.state.rootPassword}
                                            onChange={this.setChange}
                                            error={this.state.rootPassword === '' && this.state.formIsValid}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.changeShowPassword}
                                                    >
                                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col-6">
                                    <TextField
                                        name="mantainer"
                                        label="Mantainer"
                                        value={this.state.mantainer}
                                        onChange={this.setChange}
                                        error={this.state.mantainer === '' && this.state.formIsValid}
                                    />
                                </div>
                                <div className="col-6">
                                    <FormControl>
                                        <InputLabel>Country</InputLabel>
                                        <Select
                                            name="country"
                                            value={this.state.country}
                                            onChange={this.setChange}
                                            error={this.state.country === '' && this.state.formIsValid}
                                        >
                                            {
                                                countries.map((item, k) => {
                                                    return (
                                                        <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-2">
                                    Public IPs
                                </div>
                                <div className="col-10">
                                    <MultipleValueTextInput
                                        type="text"
                                        name="valuePublicIps"
                                        value={this.state.valuePublicIps}
                                        values={this.state.publicIps}
                                        onItemAdded={(item, allItems) => this.setPublicIps(item, allItems)}
                                        onItemDeleted={(item, allItems) => this.setPublicIps(item, allItems)}
                                        onChange={this.setChange}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <TextField
                                        name="publicHost"
                                        label="Public Host"
                                        value={this.state.publicHost}
                                        onChange={this.setChange}
                                        error={this.state.publicHost === '' && this.state.formIsValid}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <TextField
                                        name="city"
                                        label="City"
                                        value={this.state.city}
                                        onChange={this.setChange}
                                        error={this.state.city === '' && this.state.formIsValid}
                                    />
                                </div>
                                <div className="col-6">
                                    <TextField
                                        name="model"
                                        label="Model"
                                        value={this.state.model}
                                        onChange={this.setChange}
                                        error={this.state.model === '' && this.state.formIsValid}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <TextField
                                        name="cpuDescription"
                                        label="CPU Description"
                                        value={this.state.cpuDescription}
                                        onChange={this.setChange}
                                        error={this.state.cpuDescription === '' && this.state.formIsValid}
                                    />
                                </div>
                                <div className="col-6">
                                    <TextField
                                        name="networkInterfaceName"
                                        label="Network Interface Name"
                                        value={this.state.networkInterfaceName}
                                        onChange={this.setChange}
                                        error={this.state.networkInterfaceName === '' && this.state.formIsValid}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-4">
                                    <TextField
                                        name="os"
                                        label="OS"
                                        value={this.state.os}
                                        onChange={this.setChange}
                                        error={this.state.os === '' && this.state.formIsValid}
                                    />
                                </div>
                                <div className="col-4">
                                    <TextField
                                        name="cpuCores"
                                        label="CPU Cores"
                                        type="number"
                                        value={this.state.cpuCores}
                                        onChange={this.setChange}
                                        error={this.state.cpuCores === '' && this.state.formIsValid}
                                    />
                                </div>
                                <div className="col-4">
                                    <TextField
                                        name="maxBandwidth"
                                        label="Max Bandwidth"
                                        type="number"
                                        value={this.state.maxBandwidth}
                                        onChange={this.setChange}
                                        error={this.state.maxBandwidth === '' && this.state.formIsValid}
                                    />
                                </div>
                            </div>

                        </FormControl>

                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success" onClick={this.sendVpn}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}