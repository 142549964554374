export const API_URL                         = window.env.API_URL;
export const API_URL_SITO                    = window.env.API_URL_SITO;
export const ENVIRONMENT_APM                 = window.env.ENVIRONMENT_APM;
export const WS_URL                          = window.env.WS_URL;
export const RECAPTCHA_KEY                   = '6LcJKaIUAAAAAOvZjs5B9EOXz4xN_bTWHc7db3EM';
export const NOT_REGISTERED                  = '000000000000000000000000'; //sono 24 zeri
// export const FLUSH_TOKEN                     = '3a5bfabe-6874-4249-8fb2-e176893453d3';
export const SUPER_ADMIN                     = 'NanoMidWebSuperAdminRole';
export const TIME_POLLING_VPN_MAHCINE        = 10000;
export const TIME_POLLING_ACTIVE_JOBS        = 10000;
export const MAINTENANCE                     = 'maintenance';

/* COSTANT ROLE */
export const VPN_PASS_ROLE       = 'VpnPass';
export const PARTNERS_ROLE       = 'Partners';
export const PRODUCT_ROLE        = 'Product';
export const PROMO_ROLE          = 'Promo';
export const ROLE_ROLE           = 'Role';
export const SUPPORT_ROLE        = 'Support';
export const USER_ROLE           = 'User';
export const PAYMENT_ROLE        = 'Payment';
export const PLAYER_ROLE         = 'Player';
export const PREFERENCES_ROLE    = 'Preferences';
export const VPN_MACHINE_ROLE    = 'VpnMachine';
export const VPN_PEER_ROLE       = 'VpnPeer';
export const VPN_BOX_ROLE        = 'VpnBox';
export const BUNDLE_ROLE         = 'Bundle';
export const WORKER_ROLE         = 'Worker';
export const CHANGE_LOG_ROLE     = 'Changelog';
export const VPN_BOX_STOCKS_ROLE = 'VpnBoxStocks';
export const VPN_SETTING_PROXY   = 'InvisibleProxySetting';
export const CONFIG_ROLE         = 'Config';

/* COSTANT COUPON PRODUCT */
export const COUPON_ANDROID_LIFETIME         = 'NANOMID-COUPON-VIDEO-PLAYER-ANDROID-LIFETIME';
export const COUPON_LIFETIME                 = 'NANOMID-COUPON-VIDEO-PLAYER-LIFETIME';

/* COSTANT PREFERENCES */
export const WHITELIST             = 'LicenceWhiteListIps';
export const COUNTRY_VATS          = 'CountryVats';
export const COUNTRY_PRICINGS      = 'CountryPricings';
export const PARTNERS              = 'Partners';

/* COSTANT TYPE */
export const PLAYER_TYPE           = 'VIDEO-PLAYER';
export const VPN_TYPE              = 'VPN-PASS';
export const COUPON_TYPE           = 'COUPON';
export const VPNBOX_TYPE           = 'VPN-BOX';
export const BOX_HW_TYPE           = 'VPN-BOX-HW';
export const SHIP_TYPE             = 'SHIP';

/* COSTANT PLAYER */
export const LIFETIME              = 'VIDEO-PLAYER-LIFETIME';
export const ANDROID               = 'VIDEO-PLAYER-ANDROID-LIFETIME';
export const TRIAL                 = 'VIDEO-PLAYER-TEN-DAYS-TRIAL';

/* PRODUCT NAME VPN PASS */
export const PASS_ONE_MONTH                         = 'VPN-ONE-MONTH';
export const PASS_THREE_MONTHS                      = 'VPN-THREE-MONTHS';
export const PASS_SIX_MONTHS                        = 'VPN-SIX-MONTHS';
export const PASS_TWELVE_MONTHS                     = 'VPN-TWELVE-MONTHS';
export const PASS_TWENTYFOUR_MONTHS                 = 'VPN-TWENTYFOUR-MONTHS';
export const PASS_THIRTYSIX_MONTHS                  = 'VPN-THIRTYSIX-MONTHS';

/* COSTANT VPN BOX HW */
export const VPNBOX_ONE_MONTH_HW            = 'VPNBOX-ONE-MONTH-HW';
export const VPNBOX_TWELVE_MONTHS_HW        = 'VPNBOX-TWELVE-MONTHS-HW';
export const VPNBOX_THREE_MONTH_HW          = 'VPNBOX-THREE-MONTHS-HW';
export const VPNBOX_SIX_MONTH_HW            = 'VPNBOX-SIX-MONTHS-HW';
export const VPNBOX_TWENTYFOUR_MONTHS_HW    = 'VPNBOX-TWENTYFOUR-MONTHS-HW';
export const VPNBOX_THIRTYSIX_MONTHS_HW     = 'VPNBOX-THIRTYSIX-MONTHS-HW';

/* COSTANT SHIPPING */
export const SHIPPING                       = 'SHIPPING';

/* USER API */
export const API_USER                        = `${API_URL}/api/User`;                        //GET - PUT - POST - DELETE
export const API_SET_PASSWORD_USER           = `${API_URL}/api/User/ResetPassword`;          //PUT
export const API_LOGIN_URL                   = `${API_URL}/api/User/Login`;                  //POST
export const API_REFRESH_TOKEN_URL           = `${API_URL}/api/User/RefreshToken`;           //POST
export const API_USER_STATISTICS             = `${API_URL}/api/User/Statistics`;             //GET
export const API_USER_GROUP                  = `${API_URL}/api/User/Group`;                  //GET - DELETE
export const API_USER_GROUP_CREATE           = `${API_URL}/api/User/Group/Create`;           //POST
export const API_USER_GROUP_UPDATE           = `${API_URL}/api/User/Group/Update`;           //PUT
export const API_USER_GROUP_ASSIGN           = `${API_URL}/api/User/Group/AssignGroup`;      //PATCH
export const API_USER_ADDRESS                = `${API_URL}/api/User/Address`;                //PUT
export const API_USER_RESET_TFA              = `${API_URL}/api/User/ResetTfa`;               //PUT

/* PLAYER API */
export const API_GET_PLAYER_LIST_URL         = `${API_URL}/api/Player/GetPlayers`;           //GET
export const API_GET_PLAY_LIST_URL           = `${API_URL}/api/Player/Playlists`;            //GET
export const API_POST_PLAY_LIST_URL          = `${API_URL}/api/Player/Playlists/ChangeDns`;  //POST
export const API_SEND_NEW_PLAYLIST           = `${API_URL}/api/Player/AddPlaylist`;          //POST
export const API_SEND_MODIFY_PLAYLIST        = `${API_URL}/api/Player/UpdatePlaylist`;       //PUT
export const API_REMOVE_PLAYLIST             = `${API_URL}/api/Player/RemovePlaylist`;       //DELETE
export const API_PLAYER                      = `${API_URL}/api/Player`;                      //POST - PUT - DELETE
export const API_PLAYER_STATISTICS           = `${API_URL}/api/Player/Statistics`;           //GET
export const API_PLAYER_REBOOT               = `${API_URL}/api/Player/Reboot`;               //POST
export const API_PLAYER_SEND_MESSAGE         = `${API_URL}/api/Player/SendMessage`;          //POST
export const API_PLAYER_PLAYLIST             = `${API_URL}/api/Player/GetPlaylist`;          //GET
export const API_PLAYER_LICENCE              = `${API_URL}/api/Player/GetLicences`;          //GET
export const API_PLAYER_NEW_LICENCE          = `${API_URL}/api/Player/CreateLicencesByFilters`;          //POST
export const API_ADD_PASS_TO_PLAYER          = `${API_URL}/api/Player/....`;                 //POST

/* PAYMENT API */
export const API_PAYMENT                     = `${API_URL}/api/Payment`;                     //GET - DELETE
export const API_CANCEL_SUB                  = `${API_URL}/api/Payment/CancelSubscription`;  //POST
export const API_PAYMENT_REFUND              = `${API_URL}/api/Payment/partialRefund`;       //POST
export const API_PAYMENT_UPDATE_DEVICE       = `${API_URL}/api/Payment/ChangeDevice`;        //PUT
export const API_PAYMENT_STATISTICS          = `${API_URL}/api/Payment/Statistics`;          //GET
export const API_PAYMENT_INVOICE             = `${API_URL}/api/Payment/GetInvoices`;         //GET
export const API_CREATE_INVOICE              = `${API_URL}/api/Payment/CreateInvoice`;       //PUT

/* PROMO API */
export const API_PROMO                       = `${API_URL}/api/Promo`;                       //GET - POST - PUT - DELETE
export const API_COUPON                      = `${API_URL}/api/Promo/Coupon`;                //GET - POST - PUT - DELETE
export const API_GET_PROMO_LIST              = `${API_URL}/api/Promo/GetPromos`;             //GET
export const API_DELETE_MANY_PROMO           = `${API_URL}/api/Promo/DeleteMany`;            //DELETE

/* WORKER API */
export const API_WORKER_STATUS               = `${API_URL}/api/Worker/Status`;               //GET
export const API_WORKER_START                = `${API_URL}/api/Worker/Start`;                //POST
export const API_WORKER_STOP                 = `${API_URL}/api/Worker/Stop`;                 //POST
export const API_WORKER_ACTIVE_JOBS          = `${API_URL}/api/Worker/ActiveJobs`;           //GET - POST - DELETE
export const API_WORKER_SERVICES             = `${API_URL}/api/Worker/Services`;             //GET
export const API_WORKER_TASKS                = `${API_URL}/api/Worker/Tasks`;                //GET
export const API_WORKER_SAVED_JOBS           = `${API_URL}/api/Worker/SavedJobs`;            //GET
// export const API_WORKER_JOBS              = `${API_URL}/api/Worker/Jobs`;                 //PUT

/* VPN API */
export const API_VPN_DELETE_MACHINE          = `${API_URL}/api/VpnMachine/Delete`;                  //DELETE
export const API_VPN_SETUP_MACHINE           = `${API_URL}/api/VpnMachine/Setup`;                   //POST
export const API_VPN_START_MACHINE           = `${API_URL}/api/VpnMachine/Start`;                   //GET
export const API_VPN_STOP_MACHINE            = `${API_URL}/api/VpnMachine/Stop`;                    //GET
export const API_VPN_ADD_MACHINE             = `${API_URL}/api/VpnMachine/Add`;                     //POST
export const API_VPN_EDIT_MACHINE            = `${API_URL}/api/VpnMachine/Update`;                  //PUT
export const API_VPN_MACHINE_LIST            = `${API_URL}/api/VpnMachine`;                         //GET
export const API_VPN_PASS                    = `${API_URL}/api/VpnPass`;                            //GET - POST - PUT - DELETE
export const API_VPN_GET_PEER                = `${API_URL}/api/VpnPeer`;                            //GET
export const API_VPN_GET_PEER_BY_MACHINE_ID  = `${API_URL}/api/VpnMachine/GetPeersByMachineId`;     //GET
export const API_VPN_STATISTICS              = `${API_URL}/api/VpnPass/Statistics`;                 //GET
export const API_VPN_BOX                     = `${API_URL}/api/VpnBox`;                             //GET - PUT - DELETE
export const API_VPN_BOX_REBOOT              = `${API_URL}/api/VpnBox/Reboot`;                      //GET
export const API_VPN_BOX_COUNTRIES           = `${API_URL}/api/VpnMachine/Countries`;               //GET
export const API_VPN_BOX_DIAG                = `${API_URL}/api/VpnBox/diag`;                        //PUT
export const API_VPN_BOX_LOGS                = `${API_URL}/api/VpnBox/logs`;                        //GET

/* VPN BOX STOCKS API */
export const API_VPN_BOX_STOCK_INFO          = `${API_URL}/api/VpnBoxStocks/Info`;                   //GET
export const API_VPN_BOX_STOCK               = `${API_URL}/api/VpnBoxStocks`;                        //GET - POST - PUT - DELETE
export const API_START_RENDER_BOX_STOCK      = `${API_URL}/api/VpnBoxStocks/startReturn`;            //POST
export const API_REBOOK_BOX_STOCK            = `${API_URL}/api/VpnBoxStocks/rebook`;                 //PUT
export const API_END_RENDER_BOX_STOCK        = `${API_URL}/api/VpnBoxStocks/endReturn`;              //PUT

/* PREFERENCES API */
export const API_PREFERENCES                 = `${API_URL}/api/Preferences`;                            //GET - PUT - DELETE
export const API_FLUSH_PREFERENCES           = `${API_URL}/api/Preferences/flush`;                      //POST
// export const API_FLUSH_PREFERENCES_SITO      = `${API_URL_SITO}/api/Preferences/flush`;                 //GET
export const API_GET_PRODUCTS                = `${API_URL}/api/Product`;                                //GET
export const API_GET_SHIPPING_COUTRY         = `${API_URL}/api/Product/ShippingCountries`;              //GET
export const API_SET_TOKEN_PARTNER           = `${API_URL}/api/Partners/SetToken`;                      //PATCH
export const API_FLUSH_CONFIGURATOR          = `${API_URL}/api/config`;                                 //POST

/* SUPPORT API */
export const API_SEND_MAIL                   = `${API_URL}/api/Support/BackOfficeSendMail`;  //POST

/* BUNDLE API */
export const API_BUNDLE                  = `${API_URL}/api/Bundle`;             //GET - POST - PUT - DELETE

/* ROLE API */
export const API_GET_BACKOFFICE_METHODS      = `${API_URL}/api/Role/BackOfficeMethods`;      //GET
export const API_GET_BACKOFFICE_ROLES        = `${API_URL}/api/Role/BackOfficeRoles`;         //GET
export const API_ROLE                        = `${API_URL}/api/Role`;                         //POST - PUT - DELETE
export const API_ASSIGN_ROLE                 = `${API_URL}/api/Role/AssignRole`;              //PATCH
export const API_UNASSIGN_ROLE               = `${API_URL}/api/Role/UnassignRole`;            //PATCH

/* CHANGELOG API */
export const API_CHANGELOG                   = `${API_URL}/api/Changelog`;                   //GET - POST - DELETE
export const API_DELETE_CHANGELOG            = `${API_URL}/api/Changelog/DeleteChangelog`;   //DELETE


