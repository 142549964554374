import React, { Component, Fragment } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { VPN_SETTING_PROXY } from '../../config';
import { countries } from '../countries';
import './style.scss';
import { isEmpty } from 'lodash';


/**
 * Attivazione modale per countryVats
 *      "sendEditVpnSettingList" : se clicco OK, modifico la lista vpn setting
 *      "titleModal" : il titolo della modale quando si apre
 *      "toggle" : boolean che apre o chiude la modale
 *      "stateModal" : booleano che indica lo stato della modale (aperta o chiusa)
 */
export default class modalVpnSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country: null,
            addCountry: {}
        }
    }

    setChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,

            addCountry: {
                [event.target.value]: {
                    isEnabled: true
                }
            }
        });
    }

    confirmSend = () => {
        if (isEmpty(this.state.country) && this.props.type === "new") {
            this.props.errorNotify("Selezionare un Paese");

        } else if ((isEmpty(this.state.addCountry) && this.props.type === "new") || (isEmpty(this.props.updateCountry) && this.props.type === "remove")) {
            this.props.errorNotify("Errore riprovare");

        } else {
            this.props.sendEditVpnSettingList(VPN_SETTING_PROXY, this.props.type === "remove" ? this.props.updateCountry : this.state.addCountry)
        }
    }

    render() {
        const { stateModal, toggle, titleModal } = this.props;

        return (
            <Fragment>
                <Modal isOpen={stateModal} toggle={toggle} className="modalMail">
                    <ModalHeader toggle={toggle}>{titleModal}</ModalHeader>

                    <ModalBody>
                        <FormControl className="choose-form">
                            {
                                this.props.type === "remove" ?
                                    <div>
                                        Vuoi Rimuovere <b>{Object.keys(this.props.updateCountry)}</b> dalla lista ?
                                    </div>
                                    :
                                    <div className="row justify-content-center">
                                        <div className='col-10'>
                                            Il Paese aggiunto avrà una fake VPN
                                        </div>

                                        <div className="col-10">
                                            <FormControl>
                                                <InputLabel>Country</InputLabel>
                                                <Select
                                                    labelId="keyCountry"
                                                    name="country"
                                                    value={this.state.country}
                                                    onChange={this.setChange}
                                                >
                                                    {
                                                        countries.map((item, k) => {
                                                            return (
                                                                <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                                            );
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                            }
                        </FormControl>

                    </ModalBody>

                    <ModalFooter>
                        <button className="btn btn-success" onClick={() => this.confirmSend()}>Invia</button>
                        <button className="btn btn-info" onClick={toggle}>Annulla</button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}