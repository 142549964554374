import React, { Component, Fragment } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Select, FormControl, MenuItem, InputLabel, TextField } from '@material-ui/core';
import { countries } from '../../common/countries';

import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';

export default class formFiltersPeer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // parametri per filtri ricerca
            sortField: '',
            sortType: '',
            id: '',
            deviceId: '',
            connectedAtStart: null,
            connectedAtEnd: null,
            vpnPass: '',
            country: '',
            model: '',
            platform: '',
            version: '',
            machineId: ''
        };

        this.onChange = this.onChange.bind(this);
    }

    /**
     * Cambia il valore di quello stato.
     * 
     * @param {*} event 
     */
    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === 'sortType') {
            setTimeout(() => {
                this.sendParamsForSearch();
            }, 200);
        }
    }

    // change per le date
    setDateConnectedAtStart = (newDate) => { this.setState({ connectedAtStart: newDate }); }
    setDateConnectedAtEnd = (newDate) => { this.setState({ connectedAtEnd: newDate }); }

    setHourStart = (newHour) => {
        newHour.setHours(1);
        newHour.setMinutes(0);
        newHour.setSeconds(0);
        newHour.setMilliseconds(0);
        return newHour;
    }

    setHourEnd = (newHour) => {
        newHour.setHours(24);
        newHour.setMinutes(59);
        newHour.setSeconds(59);
        newHour.setMilliseconds(999);
        return newHour;
    }

    // Resetta tutti i filtri
    resetFilter = () => {
        this.setState({
            sortField: '',
            sortType: '',
            id: '',
            deviceId: '',
            connectedAtStart: null,
            connectedAtEnd: null,
            vpnPass: '',
            country: '',
            model: '',
            platform: '',
            version: '',
            machineId: ''
        });

        setTimeout(() => {
            this.sendParamsForSearch('vuoto');
        }, 300);
    }

    /**
    * Se viene premuto il tasto enter (Invio) avvia una ricerca
    */
    clickEnter = (event) => {
        if (event.keyCode === 13) {
            this.sendParamsForSearch();
        }
    }

    /**
     * Invia i parametri filtro per la ricerca.
     * Se il parametro vuoto è presente, invia stringhe vuote per la ricerca.
     * 
     * @param {*} label se è vuoto, manda parametri vuoti
     */
    sendParamsForSearch = (label) => {
        if (label === 'vuoto') {
            this.props.setParamsForSearch({
                sortField: '',
                sortType: '',
                id: '',
                deviceId: '',
                connectedAtStart: null,
                connectedAtEnd: null,
                vpnPass: '',
                country: '',
                model: '',
                platform: '',
                version: '',
                machineId: ''
            });
        } else {
            this.props.setParamsForSearch({
                sortField: this.state.sortField,
                sortType: this.state.sortType,
                id: this.state.id,
                deviceId: this.state.deviceId,
                connectedAtStart: this.state.connectedAtStart === null ? '' : this.setHourStart(this.state.connectedAtStart),
                connectedAtEnd: this.state.connectedAtEnd === null ? '' : this.setHourEnd(this.state.connectedAtEnd),
                vpnPass: this.state.vpnPass,
                country: this.state.country,
                model: this.state.model,
                platform: this.state.platform,
                version: this.state.version,
                machineId: this.state.machineId
            });
        }
    }

    render() {
        const { numberVpnPeer, totalPages, setChange, currentNumberPage } = this.props;
        return (
            <Fragment>
                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="id"
                            value={this.state.id}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="ID Peer"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="vpnPass"
                            value={this.state.vpnPass}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="Vpn Pass"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <FormControl>
                            <InputLabel>Cerca per Country</InputLabel>
                            <Select
                                name="country"
                                value={this.state.country}
                                onChange={this.onChange}
                            >
                                {
                                    countries.map((item, k) => {
                                        return (
                                            <MenuItem key={k} value={item.value}>{item.label}</MenuItem>
                                        );
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12">
                        <TextField
                            name="machineId"
                            value={this.state.machineId}
                            onChange={this.onChange}
                            onKeyDown={this.clickEnter}
                            label="Machine Id"
                        />
                    </div>
                </div>

                <div className="row">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="col-12 itemForSearch">
                            <KeyboardDatePicker
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="connectedAtStart"
                                label="Connesso da"
                                value={this.state.connectedAtStart}
                                onChange={this.setDateConnectedAtStart}
                            />
                        </div>

                        <div className="col-12 itemForSearch">
                            <KeyboardDatePicker
                                variant="inline"
                                id="connectedAtEnd"
                                label="Fino a"
                                value={this.state.connectedAtEnd}
                                onChange={this.setDateConnectedAtEnd}
                                // se la data di inizio è presente, disabilita tutte le date da quella data (compresa)
                                minDate={this.state.connectedAtStart ? moment(this.state.connectedAtStart).add(1, 'days')._d : false}
                                minDateMessage="Attenzione! Data precedente a quella di inizio"
                                format="dd/MM/yyyy"
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                </div>

                <div className="row itemForSearch" style={{ 'marginTop': '3rem' }}>
                    <div className="col-12 text-center">
                        <button
                            className={numberVpnPeer === '10' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChange({ target: { name: 'numberVpnPeer' } }, '10')}
                        > 10 </button>
                        <button
                            className={numberVpnPeer === '25' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChange({ target: { name: 'numberVpnPeer' } }, '25')}
                        > 25 </button>
                        <button
                            className={numberVpnPeer === '50' ? "btnAround selected" : "btnAround"}
                            onClick={() => setChange({ target: { name: 'numberVpnPeer' } }, '50')}
                        > 50 </button>
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <Pagination
                            boundaryCount={2}
                            count={parseInt(totalPages)}
                            page={parseInt(currentNumberPage)}
                            onChange={setChange}
                            color="primary"
                            showFirstButton
                            showLastButton
                            size="small"
                        />
                    </div>
                </div>

                <div className="row itemForSearch">
                    <div className="col-12 text-center">
                        <button className="btn btn-primary" style={{ 'margin': '10px' }} onClick={this.sendParamsForSearch}>
                            Cerca
                        </button>
                        <button className="btn btn-secondary" style={{ 'margin': '10px' }} onClick={this.resetFilter}>
                            Reset
                        </button>
                    </div>
                </div>

                <Accordion className="myAccordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    // aria-controls="panel1a-content"
                    // id="panel1a-header"
                    >
                        <Typography>Altri filtri</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div">
                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <FormControl>
                                        <InputLabel>Come vuoi ordinare?</InputLabel>
                                        <Select
                                            labelId="fieldOrderLabel"
                                            name="sortField"
                                            value={this.state.sortField}
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value={'Id'}>ID</MenuItem>
                                            <MenuItem value={'DeviceId'}>Device ID</MenuItem>
                                            <MenuItem value={'ConnectedAt'}>ConnectedAt</MenuItem>
                                            <MenuItem value={'VpnPass'}>VPN Pass</MenuItem>
                                            <MenuItem value={'Country'}>Country</MenuItem>
                                            <MenuItem value={'Model'}>Model</MenuItem>
                                            <MenuItem value={'Platform'}>Platform</MenuItem>
                                            <MenuItem value={'Version'}>Version</MenuItem>
                                            <MenuItem value={'MachineId'}>Machine ID</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="col-12 itemForSearch">
                                    <FormControl disabled={this.state.sortField === ''}>
                                        <InputLabel>Tipo di Ordinamento</InputLabel>
                                        <Select
                                            labelId="typeOrderLabel"
                                            name="sortType"
                                            value={this.state.sortType}
                                            onChange={this.onChange}
                                        >
                                            <MenuItem value={'asc'}>Crescente [A-Z]</MenuItem>
                                            <MenuItem value={'desc'}>Decrescente [Z-A]</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="model"
                                        value={this.state.model}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Model"
                                    />
                                </div>

                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="platform"
                                        value={this.state.platform}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Platform"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="version"
                                        value={this.state.version}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Version"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 itemForSearch">
                                    <TextField
                                        name="deviceId"
                                        value={this.state.deviceId}
                                        onChange={this.onChange}
                                        onKeyDown={this.clickEnter}
                                        label="Device ID"
                                    />
                                </div>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Fragment>
        )
    }
}